import { useState } from 'react'
import SortIcon from '@mui/icons-material/Sort'
import {
  Stack,
  IconButton,
  Popover,
  useTheme,
  useMediaQuery,
  Typography
} from '@mui/material'
import SearchBar from 'components/SearchBar'
import useNavigation from 'hooks/useNavigation'
import { COLORS } from 'styles/colors'

const sortOptions = [
  { id: 'id', title: 'Job id' },
  { id: 'title', title: 'Title A - Z' },
  { id: '-title', title: 'Title Z - A' }
]

const searchOptions = [
  { value: 'id', label: 'Job id' },
  { value: 'title', label: 'Job title' },
  { value: 'owner_id', label: 'Owner Id' },
  { value: 'recruiter_id', label: 'Recruiter Id' }
]
export default function ImportSearch() {
  const theme = useTheme()
  const { searchParams, pathname, onNavigate } = useNavigation()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selected, setSelected] = useState(searchParams.sortBy || '')

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const onOpenAnchor = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget as any)
    setOpen(previousOpen => !previousOpen)
  }

  const onSortBy = (sort: string) => {
    onNavigate(pathname, {
      search: { ...searchParams, sortBy: sort }
    })
    onCloseAnchor()
    setSelected(sort)
  }

  const onCloseAnchor = () => setOpen(previousOpen => !previousOpen)

  const canBeOpen = open && Boolean(anchorEl)
  const id = canBeOpen ? 'popper' : undefined
  const searchBy = searchOptions.find(
    option => option.value === searchParams.searchBy
  )

  return (
    <Stack
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      paddingRight={1}
    >
      <SearchBar
        isSearch
        defaultselected="id"
        options={searchOptions}
        boxProps={{ marginY: 2, marginX: 1, width: '100%' }}
        placeholder={`Search in Bullhorn by ${searchBy?.label}`}
      />
      <IconButton onClick={onOpenAnchor}>
        <SortIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        elevation={4}
        anchorEl={anchorEl}
        onClose={onCloseAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        sx={{
          'div.MuiPopover-paper': {
            borderRadius: 4,
            marginTop: 1,
            padding: 2,
            width: isMobile ? '85%' : 'auto'
          }
        }}
      >
        {sortOptions.map(({ id, title }) => (
          <Typography
            key={id}
            component="div"
            borderRadius={1}
            onClick={() => onSortBy(id)}
            sx={{
              background: selected === id ? COLORS.honey2 : COLORS.white,
              width: '100px',
              padding: '8px',
              ':hover': { background: COLORS.gray8 }
            }}
          >
            {title}
          </Typography>
        ))}
      </Popover>
    </Stack>
  )
}
